import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'

// COMPONENTS
import ProfileCard from 'components/about/profileCard'
import { Paragraph, ShadowText } from 'components/styled/typography'
import { FixedContainer } from 'components/styled/containers'
import Dropdown from 'components/styled/dropdown'
import ClosablePill from 'components/styled/pill'

// ASSETS
import { SCREEN } from 'styles/screens'
import { getOptions, getTranslation } from '../../data/dictionary'
import DEFAULT_PROFILE_IMAGE from 'images/profile_placeholder.png'
import { config } from '../../config'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`
const StyledContainer = styled(FixedContainer)`
  flex-direction: column;
  align-items: flex-start;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    max-width: 100%;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  overflow: hidden;
  margin: calc(var(--spacing) * -5);
  margin-top: calc(var(--spacing) * 10);

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    flex-wrap: nowrap;
    overflow-x: auto;
    margin: calc(var(--spacing) * -2) calc(var(--spacing) * -2) 0
      calc(var(--spacing) * -2);
    > div {
      min-width: 70vw;
      flex: 0 0 auto;
      flex-shrink: 0;
      padding-bottom: calc(var(--spacing) * 10);
    }
  }

  > div {
    flex-basis: 23%;
    justify-content: flex-start;
    margin: calc(var(--spacing) * 3);
  }
`
const FilterInputs = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;

  > div {
    :first-child {
      margin-right: calc(var(--spacing) * 3);
    }

    width: calc(var(--spacing) * 60);
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    justify-content: space-between;
    > div {
      flex-basis: 45%;
    }
  }
`

const FilterByLabel = styled.p`
  flex-basis: 100%;
  font-weight: 600;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    margin: 12px 0;
  }
`
const Heading = styled.div`
  margin-bottom: 0;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    margin-bottom: 0;
    h2 {
      font-size: 2.4rem;
    }
  }
`

const SelectedFilters = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--spacing) * 3);

  > div {
    margin: 0 calc(var(--spacing) * 2) calc(var(--spacing) * 2) 0;

    :last-child {
      margin-right: 0;
    }
  }
`

const TrusteeAndMentor = ({ members }) => {
  const [countryFilters, setCountryFilters] = useState([])
  const [industryFilters, setIndustryFilters] = useState([])
  return (
    <Container>
      <StyledContainer>
        <Heading>
          <ShadowText highlight>
            <h2>{getTranslation('Trustees & Mentors','Trustees & Mentors')}</h2>
          </ShadowText>
        </Heading>

        <FilterByLabel>FILTER BY</FilterByLabel>
        <FilterInputs>
          <Dropdown
            placeholder="Industries"
            value={null}
            options={getOptions('industry')}
            onSelect={({ value }) => {
              setIndustryFilters([...new Set([...industryFilters, value])])
            }}
          />
          <Dropdown
            placeholder="Countries"
            value={null}
            options={getOptions('country')}
            onSelect={({ value }) => {
              setCountryFilters([...new Set([...countryFilters, value])])
            }}
          />
        </FilterInputs>
        <SelectedFilters>
          {industryFilters.map(i => (
            <ClosablePill
              key={i}
              onClose={() =>
                setIndustryFilters(industryFilters.filter(item => item !== i))
              }
            >
              <Paragraph>{i}</Paragraph>
            </ClosablePill>
          ))}
          {countryFilters.map(i => (
            <ClosablePill
              key={i}
              onClose={() =>
                setCountryFilters(countryFilters.filter(item => item !== i))
              }
            >
              <Paragraph>{i}</Paragraph>
            </ClosablePill>
          ))}
        </SelectedFilters>
        <CardContainer>
          {members
            .filter(member => {
              return (
                (!countryFilters.length ||
                  countryFilters.includes(member.country)) &&
                (!industryFilters.length ||
                  industryFilters.includes(member.industry))
              )
            })
            .map((member, index) => (
              <ProfileCard
                imageUrl={`${
                  member.image
                    ? `${config.CDN_BASE_URL}/${member.image}`
                    : DEFAULT_PROFILE_IMAGE
                }`}
                key={index}
                name={`${member.firstName} ${member.lastName}`}
                role={`${member.position} @ ${member.company}`}
                content={member.content}
                social={{
                  twitter: member.twitter
                    ? member.twitter.includes('twitter.com')
                      ? member.twitter
                      : `https://twitter.com/${member.twitter.replace('@', '')}`
                    : null,
                  linkedin: `${member.linkedin}`,
                }}
              />
            ))}
        </CardContainer>
      </StyledContainer>
    </Container>
  )
}

export default TrusteeAndMentor
