import * as React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby-link'

// COMPONENTS
import { ShadowText } from 'components/styled/typography'

// ASSETS
import { SCREEN } from 'styles/screens'
import { FixedContainer } from '../styled/containers'
import { getTranslation } from '../../data/dictionary'
import axios from 'axios'
import { config } from '../../config'

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px;
  min-height: 700px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`

const DescriptionContainer = styled.div`
  flex: 1 0 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div {
    margin-bottom: 40px;
  }

  > p {
    max-width: 570px;
    overflow-wrap: break-word;
    hyphens: auto;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    flex: unset;
    > div {
      margin-bottom: 12px;
    }
  }
`

const PriceBoxes = styled.div`
  flex: 1 0 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const PriceBox = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 45%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 2px solid var(--primary);
  min-height: 229px;
  //min-width: 345px;
  margin: 12px;
  padding: 28px;
  cursor: pointer;

  &:before {
    transition: background-color 150ms ease-in-out;
    position: absolute;
    top: -7%;
    left: -5%;
    content: '';
    background-color: ${({ selected }) =>
      selected ? 'var(--secondary)' : 'unset'};
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  &:hover {
    &:before {
      background-color: var(--secondary);
    }
  }

  > div {
    display: flex;
    align-items: flex-end;

    > h4,
    h6 {
      margin: 0;
      line-height: 1;
    }

    > h6 {
      font-weight: 400;
    }
  }

  > p {
    margin: 8px 0;
    line-height: 1.5;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    h4 {
      font-size: 3rem;
    }

    h6 {
      font-size: 1.4rem;
    }

    p {
      font-size: 1.2rem;
    }
  }
`

const BecomeTrusteeSection = () => {
  const [plans, setPlans] = React.useState([])

  React.useEffect(async () => {
    try {
      let plans = (await axios.get(`${config.API_BASE_URL}/plans`)).data
      setPlans(plans)
    } catch (e) {}
  }, [])

  const [selected, setSelected] = React.useState()
  return (
    <Container>
      <FixedContainer>
        <DescriptionContainer>
          <ShadowText highlight>
            <h3>{getTranslation('become_a_trustee', 'Become a trustee')}</h3>
          </ShadowText>
          <p>
            {getTranslation(
              'become_a_trustee_description',
              'Our business model is based on the individuals (CEOs, Founders,\n' +
                '            Directors, etc.) and companies willing to accelerate the education\n' +
                '            in Armenia and hire our graduates in the near future. We will accept\n' +
                '            trustees who will donate recurring payments (exp. $100-$500/mo) to\n' +
                '            run two batches each year.',
            )}
          </p>
        </DescriptionContainer>
        <PriceBoxes>
          {plans.map((plan, index) => {
            return (
              <PriceBox
                key={index}
                selected={selected === index}
                onClick={() => {
                  setSelected(index)
                  navigate('/subscription')
                }}
              >
                <div>
                  <h4>{plan.price}$</h4>
                  <h6>/month</h6>
                </div>
                <p>{plan.shortDescription}</p>
              </PriceBox>
            )
          })}

          <PriceBox
            selected={selected === 4}
            onClick={() => {
              setSelected(4)
              navigate('/subscription')
            }}
          >
            <div>
              <h4>$__K</h4>
              <h6>/for 2 years</h6>
            </div>
            <p>I want to help kick-start and be a founding trustee...</p>
          </PriceBox>
        </PriceBoxes>
      </FixedContainer>
    </Container>
  )
}

export default BecomeTrusteeSection
